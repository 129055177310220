<template>
    <div class="pks-update-modal">
        <transition name="fade" mode="out-in">
            <div v-if="isShowModal" class="triangle"></div>
        </transition>
        <Modal :title="title" v-model="isShowModal" :isFloat="true">
            <template v-slot:modal-content>
                <div
                    v-for="(item, index) in pksSyncStatus"
                    :key="index"
                    class="modal-item-wrapper"
                >
                    <div :label="item.description" class="modal-item">
                        <span style="font-weight: bold"
                            >{{ item.description }}
                        </span>
                        :
                        {{ $toLocalString(item.startAt) }}
                    </div>
                </div>
                <div v-if="pksSyncStatus?.length === 0" class="error-message">
                    讀取更新時間時發生錯誤，請重新更新PKS。<br />如持續發生此情形，請洽飛鴿客服。
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: 'AdminPksUpdateTime',
    emits: ['update:modelValue'],
    components: {
        Modal
    },
    props: {
        modelValue: {
            type: Boolean
        },
        isVisible: {
            type: Boolean
        },
        success: {
            type: Boolean
        },
        pksSyncStatus: {
            type: Array
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        }
    },
    watch: {
        isShowModal: function () {
            this.$emit('update:modelValue', this.isShowModal)
        },
        modelValue: function () {
            this.isShowModal = this.modelValue
        }
        // pksUpdateTime: {
        //     handler() {
        //         this.taskList = this.pksUpdateTime
        //     },
        //     deep: true,
        //     immediate: true
        // }
    },
    data() {
        return {
            isShowModal: this.modelValue,
            title: 'PKS 最後更新時間',
            errorMessage: '切換業務員查詢狀態時出現錯誤..建議您重新整理頁面',
            taskList: []
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    font-size: 14px;
    color: $secondary-grey;
}

.modal-item-wrapper {
    .modal-item {
        color: $sixth-black;
        font-size: 14px;
        margin: 0 5px 15px 5px;
        line-height: 20px;
    }
    &:last-child .modal-item {
        margin: 0 5px 0px 5px;
    }
}

.pks-update-modal {
    z-index: 7;
    & :deep(.not-scroll) {
        overflow: initial;
        position: relative;
    }
    @media screen and(min-width: 1280px) {
        z-index: 5;
        :deep(.modal.float) {
            position: absolute;
            z-index: 1;
            top: 135px;
            left: 30%;
        }
        :deep(.modal-size) {
            width: 320px;
        }
    }

    @media (min-width: 577px) and (max-width: 1280px) {
        :deep(.modal.float) {
            position: fixed;
            width: 100vw;
            height: 100%;
            z-index: 10;
            .modal-background {
                background-color: red;
                background: rgba(0, 0, 0, 0.6);
                padding: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .triangle {
        display: block;
        position: absolute;
        top: 118px;
        left: calc(50% - 100px);
        width: 0;
        height: 0;
        z-index: 2;
        border-style: solid;
        border-width: 0 10px 20px 10px;
        border-color: transparent transparent $primary-white transparent;
        @media screen and (max-width: 1280px) {
            display: none;
        }
    }
}
.error-message {
    color: $fourth-black;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
}
</style>
